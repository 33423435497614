@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --debit-from: #9644b466 0%;
  --debit-via: #578ec066 66.88%;
  --debit-to: #1dd2d266 98.02%;
  /* New website */
  --color-primary: #5affd2;
  --color-darkish: #32285a;
  --color-grey: #8f8f8f;
  --color-grey-dark: #7d7d7d;
  --color-grey-light: 174 176 176;
  /* Font size */
  --fs-brand: 65px;
  --fs-header: 40px;
  --fs-caption: 32px;
  --fs-caption-sm: 25px;
  --fs-subheader: 25px;
  --fs-body: 18px;
  --fs-body-sm: 14px;
  /* Line height */
  --lh-brand: 70px;
  --lh-header: 46px;
  --lh-caption: 36px;
  --lh-caption-sm: 32px;
  --lh-subheader: 32px;
  --lh-body: 26px;
  --lh-body-md: 26px;
  --lh-body-sm: 20px;
  /* Letter spacing */
  --ls-tight-4: -0.04em;
  --ls-tight-3: -0.03em;
  --ls-tight-2: -0.02em;
  --ls-tight-1: -0.01em;
  /* Letter spacing */
  --ls-tight-4: -0.04em;
  --ls-tight-3: -0.03em;
  --ls-tight-2: -0.02em;
  --ls-tight-1: -0.01em;
}

@media screen and (min-width: 768px) {
  :root {
    /* Font size */
    --fs-brand: 100px;
    --fs-header: 65px;
    --fs-caption: 45px;
    --fs-caption-sm: 40px;
    --fs-subheader: 29px;
    --fs-body: 21px;
    --fs-body-sm: 18px;
    /* Line height */
    --lh-brand: 110px;
    --lh-header: 80px;
    --lh-caption: 50px;
    --lh-caption-sm: 48px;
    --lh-subheader: 36px;
    --lh-body: 29px;
    --lh-body-md: 25px;
    --lh-body-sm: 24px;
  }
}

/* Customizing forms */
@layer base {
  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    @apply rounded-[3px] border border-[#AEB0B0] py-[9px] px-[19px] w-full focus:outline-none focus:border-[#c39eff] focus:ring-1 focus:ring-[#c39eff]/50;
  }

  [type="checkbox"] {
    @apply font-inter rounded w-5 h-5 border-gray-400 transition text-blue-medium focus:ring-blue-400;
  }

  [type="radio"] {
    @apply font-inter border-gray-300 text-blue-medium focus:ring-blue-medium;
  }

  label {
    @apply block font-inter font-semibold text-sm leading-6 text-dark-dark mb-1.5;
  }
}

input:disabled {
  background-color: #f2f2f2 !important;
}

/* Fonts */
@font-face {
  font-family: SF Compact Display;
  src: url(./assets/fonts/SFCompactDisplay-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: SF Compact Display;
  src: url(./assets/fonts/SFCompactDisplay-Medium.ttf);
  font-weight: 600;
}

@font-face {
  font-family: SF Compact Display;
  src: url(./assets/fonts/SFCompactDisplay-Regular.ttf);
  font-weight: 500;
}

/* Inter fonts */
@font-face {
  font-family: Inter;
  src: url(./assets/fonts/Inter-Thin.ttf);
  font-weight: 100;
}

@font-face {
  font-family: Inter;
  src: url(./assets/fonts/Inter-ExtraLight.ttf);
  font-weight: 200;
}

@font-face {
  font-family: Inter;
  src: url(./assets/fonts/Inter-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: Inter;
  src: url(./assets/fonts/Inter-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Inter;
  src: url(./assets/fonts/Inter-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: Inter;
  src: url(./assets/fonts/Inter-SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: Inter;
  src: url(./assets/fonts/Inter-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: Inter;
  src: url(./assets/fonts/Inter-ExtraBold.ttf);
  font-weight: 800;
}

@font-face {
  font-family: Inter;
  src: url(./assets/fonts/Inter-Black.ttf);
  font-weight: 900;
}

@font-face {
  font-family: "PP Mori";
  src: url("./assets/fonts/pp-mori/PPMori-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: "PP Mori";
  src: url("./assets/fonts/pp-mori/PPMori-SemiBold.otf");
  font-weight: 600;
}

/* Base CSS */
body {
  @apply antialiased;
}

/* Typography */
h1.title-1,
.title-1 {
  font-size: 32px;
  line-height: 52px;
  font-family: "Inter", sans-serif;
  letter-spacing: -0.0075em;
}

h2.title-2 {
  font-size: 18px;
  line-height: 30px;
  font-family: "Inter", sans-serif;
  letter-spacing: -0.0075em;
}

h3.title-3 {
  font-size: 22px;
  line-height: 36px;
  font-family: "Inter", sans-serif;
  letter-spacing: -0.0075em;
}

h4.title-4 {
  font-size: 14px;
  line-height: 24px;
  font-family: "Inter", sans-serif;
  letter-spacing: -0.01em;
}

h6.title-6 {
  font-size: 11px;
  line-height: 14px;
  font-family: "Inter", sans-serif;
  letter-spacing: -0.0075em;
}

.badge-text {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  letter-spacing: -0.0075em;
}

.badge-text-sm {
  font-size: 11px;
  line-height: 18px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  letter-spacing: -0.0075em;
  border-radius: 9px;
}

.body-text {
  font-family: "PP Mori";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

h2 {
  font-size: 1.75rem;
  @apply font-inter font-bold leading-none;
}

h3 {
  @apply font-inter text-2xl leading-8 font-bold;
}

h4 {
  @apply font-inter text-xl leading-7 font-bold;
}

h5 {
  @apply font-inter text-sm leading-5 font-bold;
}

.button {
  @apply flex items-center min-w-max px-5 py-3 rounded-lg text-white font-inter text-base leading-snug;
}

.error {
  @apply font-inter text-red font-medium text-sm leading-4 mt-1;
}

.web-padding {
  @apply 2xl:px-16 xl:px-14 lg:px-10 px-5;
}

/* Custom CSS */
.-tracking-wide {
  letter-spacing: -0.01em;
}

.-tracking-widest {
  letter-spacing: -0.0075em;
}

.translate-x-5-5 {
  transform: translateX(22px);
}

.page-link:hover .sidebar-icon {
  color: #4f4f4f;
}

.sidebar-icon {
  background: #efefef;
  color: #828282;
  width: 28px;
  height: 28px;
  padding: 5px;
}

.active-page-link .sidebar-icon,
.active-page-link:hover .sidebar-icon {
  background: #ddeafe;
  color: #2a7dfb;
}

.filter-button {
  font-size: 14px;
  line-height: 18px;
  font-family: "SF Compact Display", sans-serif;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.0075em;
  padding: 12px 14px;
}

.font-sf-compact {
  font-family: "SF Compact Display", sans-serif;
}

/* .active-page-link::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: calc(100% + 30px);
  transform: translateY(-50%);
  background: url("../icons/active-page-circle.svg") no-repeat center;
  height: 6px;
  width: 6px;
} */
* {
  scroll-behavior: smooth;
}

@keyframes animation-rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Forms */
.form-group {
  @apply space-y-1.5 flex flex-col mb-8;
}

.learn {
  background: linear-gradient(46.19deg, #692de9 5.66%, #b112e9 94.35%);
}

.join-our {
  background: linear-gradient(46.19deg, #11a49b 5.66%, #90fc8e 94.35%);
}

.gradient-background {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: -1;
  width: 100%;
  height: auto;
  min-height: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  /* background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#3f5ff6),
    color-stop(62%, #8dc4fa),
    to(#e9bbc4)
  );
  background-image: linear-gradient(90deg, #3f5ff6, #8dc4fa 62%, #e9bbc4); */
  background: linear-gradient(46.19deg, #692de9 5.66%, #b112e9 94.35%),
    linear-gradient(46.19deg, #7b61ff 5.66%, #59a0d2 49.09%, #29bb89 93.43%);
  text-align: center;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.gradient-text-clipping {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#3f5ff6),
    color-stop(62%, #8dc4fa),
    to(#e9bbc4)
  );
  background-image: linear-gradient(90deg, #3f5ff6, #8dc4fa 62%, #e9bbc4);
  /* background: linear-gradient(46.19deg, #692de9 5.66%, #b112e9 94.35%),
    linear-gradient(46.19deg, #7b61ff 5.66%, #59a0d2 49.09%, #29bb89 93.43%); */
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.backed-by-img {
  width: 10%;
}

.box-gradient {
  position: absolute;
  left: -3px;
  top: -3px;
  right: -3px;
  bottom: -3px;
  z-index: -1;
  border-radius: 14px;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#3f5ff6),
    color-stop(62%, #8dc4fa),
    to(#e9bbc4)
  );
  background-image: linear-gradient(90deg, #3f5ff6, #8dc4fa 62%, #e9bbc4);
}

.feature-black-image-holder {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 112%;
  padding: 3.5em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-radius: 10px;
}

.gradient-background-2 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: -1;
  width: 100%;
  height: auto;
  min-height: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#3f5ff6),
    color-stop(62%, #8dc4fa),
    to(#e9bbc4)
  );
  background-image: linear-gradient(90deg, #3f5ff6, #8dc4fa 62%, #e9bbc4);
  text-align: center;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.block-image-container {
  width: 100%;
  padding: 4em 3.5em;
  border-radius: 14px;
  background-position: 0px 0px;
  background-size: auto;
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
}

.block-image-container.gradient {
  position: relative;
  overflow: hidden;
  padding-right: 3.5em;
  padding-left: 3.5em;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#3f5ff6),
    color-stop(62%, #8dc4fa),
    to(#e9bbc4)
  );
  background-image: linear-gradient(90deg, #3f5ff6, #8dc4fa 62%, #e9bbc4);
}

.block-image-content.gradient {
  width: 45%;
  padding-right: 2em;
}

.block-right-image {
  position: absolute;
  left: 45%;
  top: 4.5em;
  border-radius: 12px;
}

@media screen and (max-width: 991px) {
  .block-right-image {
    left: 45%;
    top: 2em;
  }
}

@media screen and (max-width: 767px) {
  .block-image-content.gradient {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-right: 0em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .block-right-image {
    position: relative;
    left: 0%;
    top: 0em;
    display: block;
    margin-top: 2em;
  }
}

/* Disable Arrows in Input Type Number  */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Custom Scrollbar */
/* width */
.custom-scroll {
  overflow-y: scroll;
  scrollbar-color: #e4e7ec #ffffff;
  scrollbar-width: 6px;
}

.custom-scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
.custom-scroll::-webkit-scrollbar-track {
  border-radius: 25px;
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
  background: #e4e7ec;
  border-radius: 25px;
}

/* Handle on hover */
.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #d1d5db;
}

/* React Tooltip */
.customeTheme {
  font-size: 14px !important;
  line-height: 18px !important;
  margin-top: -5px !important;
  color: #fff !important;
  background-color: #000 !important;
  font-weight: 400 !important;
}

.customeTheme.place-top,
.customeTheme::after {
  border-top-color: #000 !important;
  border-top-style: solid !important;
  border-top-width: 6px !important;
}

/* Animate Progress Bar */
@keyframes progress {
  0% {
    width: 100%;
  }

  100% {
    width: 0%;
  }
}

/* Add on CSS */
.page-link .sidebar-icon {
  transition: transform 250ms cubic-bezier(0.2, 0.8, 0.4, 1);
}

.page-link:active .sidebar-icon {
  transform: scale(0.85);
}

button:hover {
  transform: scale(1.02);
}

button {
  transition: transform 250ms cubic-bezier(0.2, 0.8, 0.4, 1);
}

button:active {
  transform: scale(0.98);
}

input:hover {
  transform: scale(1);
}

input {
  transition: transform 250ms cubic-bezier(0.2, 0.8, 0.4, 1);
}

input:active {
  transform: scale(0.99);
}

.zebec-card-bg {
  background: linear-gradient(252.72deg, #dfdfdf 13.23%, #ccebe0 95.31%);
}

.html-wrapper {
  @apply max-w-[1280px] px-5 md:px-10 mx-auto;
}

/* New Website */
.web-container {
  max-width: 1440px;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: auto;
  margin-right: auto;
}

.web-container * {
  @apply !font-ppmori;
}

@media screen and (min-width: 768px) {
  .web-container {
    padding-left: 48px;
    padding-right: 48px;
  }
}

.zebec-banner,
.zebec-labs-banner {
  width: 100%;
  min-height: 800px;
  height: calc(100vh - 95px);
}

.zebec-card-banner {
  width: 100%;
  min-height: 850px;
  height: auto;
  background: linear-gradient(252.72deg, #dfdfdf 13.23%, #ccebe0 95.31%);
}

@media screen and (min-width: 640px) {
  .zebec-card-banner {
    min-height: 900px;
  }
}

@media screen and (min-width: 768px) {
  .zebec-card-banner {
    min-height: 800px;
    height: calc(100vh - 95px);
  }
}

@media screen and (max-width: 768px) {
  .hero-wave-bg {
    background-image: url("./assets/images/hero-wave-2.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100% !important;
    height: 100% !important;
    object-position: center;
    display: flex;
    align-items: flex-end;
  }

  .hero-contents {
    display: flex;
    flex-direction: column-reverse;
  }

  .hero-gif-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.zebec-black-hero-bg {
  background-image: url("./assets/images/zebec-black-hero-bg.png");
  background-repeat: no-repeat;
  /* display: flex;
  align-items: flex-end; */
}
.partners-cards-bg {
  background-image: url("./assets/images/partner/partners-card-img.png");
  background-repeat: no-repeat;
  background-size: cover;
}

/* Hero content */
.hero-contents {
  display: flex;
  justify-content: center;
}

/* Swiper */
.swiper {
  width: 100%;
  height: 100%;
  padding-bottom: 40px !important;
}

.mySwiper .swiper-slide {
  width: auto !important;
  display: flex;
  align-items: center;
}

.mySwiper .swiper-wrapper {
  transition-timing-function: linear;
}

.swiper-pagination-bullet {
  width: 10px !important;
  height: 10px !important;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #08e0a3 !important;
}

.dropdown-menu {
  top: calc(100% + 1rem);
  height: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  transform: translateY(-15px);
  transition: opacity 200ms ease-in-out, transform 200ms ease-in-out,
    height 200ms ease-in-out;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.06),
    0px 2px 1px rgba(0, 0, 0, 0.04), 0px 1px 1px rgba(0, 0, 0, 0.02);
}

.dropdown.active > .data-dropdown-button + .dropdown-menu {
  height: 312px;
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.dropdown-menu .dropdown-inner-link {
  color: #141414;
  border: 1px solid rgba(20, 20, 20, 0.08);
  box-shadow: 0px 1px 4px rgba(20, 20, 20, 0.01),
    0px 2px 1px rgba(20, 20, 20, 0.01);
}

.home-hero-gradient {
  background: linear-gradient(253deg, #dfdfdf 13.23%, #ccebe0 95.31%);
}

.loader {
  width: 40px;
  height: 40px;
  border: 5px dotted #fff;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.referral-have-questions,
.referral-got-ideas,
.bonk-curious-for-more,
.bonk-revolution-floks,
.burrrd-revolution-floks,
.magic-curious-for-more,
.magic-revolution-floks,
.silly-crave-more,
.silly-dragon-tribe {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.referral-have-questions {
  background-image: url("./assets/images/referral-have-questions-bg.png");
}

.referral-got-ideas,
.bonk-curious-for-more {
  background-image: url("./assets/images/referral-got-ideas-bg.png");
}

.magic-revolution-floks {
  background-image: url("./assets/images/partners/magic-revolution-flocks.png");
}

.magic-curious-for-more {
  background-image: url("./assets/images/partners/magic-curious-for-more.png");
}

.bonk-revolution-floks {
  background-image: url("./assets/images/bonk-revolution-floks.png");
}

.burrrd-revolution-floks {
  background-image: url("./assets/images/partners/burrrd-stab-bg.png");
}

.silly-crave-more {
  background-image: url("./assets/images/partners/crave-more-bg.png");
}

.silly-dragon-tribe {
  background-image: url("./assets/images/partners/join-silly-tribe-bg.png");
}

@media screen and (min-width: 1024px) {
  .silly-hero-bg,
  .burrrd-hero-bg,
  .bonk-hero-bg,
  .brett-hero-bg {
    min-height: 600px !important;
  }
}

@media screen and (max-width: 767px) {
  .silly-crave-more {
    background-image: url("./assets/images/partners/crave-more-mobile-bg.png");
  }

  .burrrd-revolution-floks {
    background-image: url("./assets/images/partners/burrrd-stab-mobile-bg.png");
  }

  .magic-revolution-floks {
    background-image: url("./assets/images/partners/magic-mobile-revolution-flocks.png");
  }

  .referrall-hero-bg,
  .burrrd-hero-bg,
  .bonk-hero-bg,
  .brett-hero-bg,
  .partner-hero-bg {
    height: 650px !important;
  }
}

@media screen and (min-width: 640px) and (max-width: 1023px) {
  .silly-hero-bg,
  .burrrd-hero-bg,
  .bonk-hero-bg,
  .brett-hero-bg {
    min-height: 800px !important;
  }
}

@media screen and (max-width: 639px) {
  .silly-hero-bg,
  .burrrd-hero-bg,
  .bonk-hero-bg,
  .brett-hero-bg {
    min-height: 650px !important;
  }
}

.promo-card-1 {
  background-image: url("./assets/images/zbc-promo-card-1-bg.png");
}

.promo-card-2 {
  background-image: url("./assets/images/zbc-promo-card-2-bg.png");
}

.promo-card-3 {
  background-image: url("./assets/images/zbc-promo-card-3-bg.png");
}

.promo-card-1,
.promo-card-2,
.promo-card-3 {
  background-size: cover;
  background-repeat: no-repeat;
}

/* Card style */
.zebec-cards-bg {
  background-color: #f8f8ff;
}

/* Icon Gradient color */
.bg-icon-gradient {
  background: var(
    --Linear,
    linear-gradient(119deg, #c39eff 3.38%, #3c78eb 103.77%)
  );
}

/* For gradient text */
.gradient-text {
  background: var(
    --Linear,
    linear-gradient(119deg, #c8a0fa 3.38%, #1e50ff 103.77%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradient-text-border-b {
  position: relative;
}
.gradient-text-border-b::after {
  content: "";
  width: 100%;
  height: 1px;
  left: 0;
  bottom: -2px;
  position: absolute;
  background: var(
    --Linear,
    linear-gradient(119deg, #c8a0fa 3.38%, #1e50ff 103.77%)
  );
}
/* Make text underlined */
.text-underlined {
  text-decoration: underline;
}

/* Button */
button.gradient-button-border {
  /* @apply border-0 z-[2]; */
  border: 0;
  z-index: 2;
  transition: all ease-in 300ms;
  position: relative;
}

button.gradient-button-border::after {
  --m-i: linear-gradient(#fff, #fff);
  --m-o: content-box, padding-box;
  content: "";
  @apply absolute left-0 top-0 w-full h-full p-px rounded-full;
  background-image: linear-gradient(118.88deg, #c39eff 3.38%, #3c78eb 103.77%);
  -webkit-mask-image: var(--m-i), var(--m-i);
  mask-image: var(--m-i), var(--m-i);
  -webkit-mask-origin: var(--m-o);
  mask-origin: var(--m-o);
  -webkit-mask-clip: var(--m-o);
  mask-composite: exclude;
}

button.gradient-button-border:after,
button.gradient-button-border:hover {
  background-image: linear-gradient(118.88deg, #c39eff 3.38%, #3c78eb 103.77%);
  color: #fff;
}

button.gradient-button-border:hover .bg-icon-gradient {
  background: transparent;
}

button:hover button.gradient-text {
  background: transparent;
}

button.green-button-border {
  border: 1px solid #5affd2;
}

button.green-button-border:hover {
  transition: all ease-in 300ms;
  background: #5affd2;
  color: #fff;
}

/* End Button */
.brett-crave-more {
  background: linear-gradient(119deg, #c8a0fa 3.38%, #1e50ff 103.77%);
}

.page-banner-background {
  background-image: url("./assets/images/page-header-background.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

@media screen and (max-width: 767px) {
  .page-banner-background {
    background-image: url("./assets/images/page-header-mobile-background.png");
  }
}

/* Notification Bar Style */
.notification-bar {
  border-radius: 4px;
  padding: 10px 20px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.notification-bar.success {
  background-color: rgba(36, 132, 76, 0.9);
  color: #fff;
}

.notification-bar.error {
  background-color: rgba(214, 86, 86, 0.9);
  color: #fff;
}

.underline {
  text-decoration: underline !important;
}
.gradient-border-y::before,
.gradient-border-y::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  left: 0;
  background: linear-gradient(119deg, #c8a0fa 3.38%, #1e50ff 103.77%);
}
.gradient-border-y::before {
  top: 0;
}
.gradient-border-y::after {
  bottom: 0;
}
